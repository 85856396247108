import React, { useState, useReducer, useEffect } from 'react';
import styled from 'styled-components';
import { TextField, Button } from '@material-ui/core';

import { validateName } from '../utils/name';
import CardImage from './CardImage';
import { validateZip } from '../utils/zip';
import {
  validateCardNumber,
  validateExpiryDate,
  getCardType,
  isCardNumberTooLong,
} from '../utils/creditcard';

const MAX_NAME_LENGTH = 50;
const MAX_DATE_LENGTH = 7;
const MAX_ZIP_LENGTH = 5;

const FIVE_DIGIT_REGEX = /(?:([0-9]{4})([0-9]))/g;
const THREE_DIGIT_REGEX = /^(?:([0-9]{2})([0-9]))/g;
const TAIL_SLASH_REGEX = /\/$/g;
const NON_DIGIT_REGEX = /[^\d]/g;
const NON_DIGIT_AND_SPACE_REGEX = /[^\d ]/g;
const NON_DIGIT_AND_SLASH_REGEX = /[^\d\/]/g;
const LETTER_REGEX = /[a-zA-Z]/g;
const INVALID_NAME_CHARACTERS_REGEX = /[^a-zA-Z, \.\-']/g; //eslint-disable-line no-useless-escape

const Form = styled.form`
  display: grid;
  min-width: 250px;
`;

const StyledTextField = styled(TextField)`
  min-height: 70px;
`;

const StyledButton = styled(Button)`
  margin: 25px;
`;

const FORM_ACTIONS = {
  SET_NAME: 'SET_NAME',
  SET_CARD_NUMBER: 'SET_CARD_NUMBER',
  SET_IS_CARD_NUMBER_VALID: 'SET_IS_CARD_NUMBER_VALID',
  SET_EXPIRATION: 'SET_EXPIRATION',
  SET_ZIP: 'SET_ZIP',
  RESET_FORM: 'RESET_FORM',
};

const initialState = {
  name: '',
  isNameValid: true,
  cardNumber: '',
  maskedCardNumber: '',
  isCardNumberValid: true,
  cardType: '',
  expiration: '',
  isExpirationValid: true,
  zip: '',
  isZipValid: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case FORM_ACTIONS.SET_NAME:
      const name = action.payload;
      const containsInvalidCharacters = !!name.match(INVALID_NAME_CHARACTERS_REGEX);

      if (name.length === 1) {
        const isLetter = !!name.match(LETTER_REGEX);
        if (isLetter) {
          return {
            ...state,
            name,
            isNameValid: false,
          };
        }
      } else if (name.length <= MAX_NAME_LENGTH && !containsInvalidCharacters) {
        const isNameValid = validateName(name);

        return {
          ...state,
          name,
          isNameValid: name.length === 0 || isNameValid
        };
      }
      
      return state;
    case FORM_ACTIONS.SET_CARD_NUMBER:
      const cardNumber = action.payload;
      const cardNumberContainsNonDigitOrNonSpace = !!cardNumber.match(NON_DIGIT_AND_SPACE_REGEX);

      if (!isCardNumberTooLong(cardNumber) && !cardNumberContainsNonDigitOrNonSpace) {
        const cardType = getCardType(cardNumber);
  
        const formattedCardNumber = cardNumber.replace(FIVE_DIGIT_REGEX, '$1 $2').trim();
        const isCardNumberValid = validateCardNumber(cardNumber);

        let maskedCardNumber = '';
        if (isCardNumberValid) {
          maskedCardNumber =
            cardNumber.substring(0, cardNumber.length - 5).replace(/[0-9]/g, '.') +
            cardNumber.substring(cardNumber.length - 5);
        }

        return {
          ...state,
          cardNumber: formattedCardNumber,
          maskedCardNumber: maskedCardNumber,
          isCardNumberValid: cardNumber.length === 0 || isCardNumberValid,
          cardType,
        };
      }

      return state;
    case FORM_ACTIONS.SET_IS_CARD_NUMBER_VALID:
      return { ...state, isCardNumberValid: action.payload};
    case FORM_ACTIONS.SET_EXPIRATION:
      const expiration = action.payload;
      const expirationContainsNonDigitOrNonSlash = !!expiration.match(NON_DIGIT_AND_SLASH_REGEX);

      if (expiration.length <= MAX_DATE_LENGTH && !expirationContainsNonDigitOrNonSlash) {
        const isExpirationValid = validateExpiryDate(expiration);
        const formattedExpiration = expiration.replace(THREE_DIGIT_REGEX, '$1/$2').replace(TAIL_SLASH_REGEX, '');
  
        return {
          ...state,
          expiration: formattedExpiration,
          isExpirationValid: expiration.length === 0 || isExpirationValid,
        };
      }

      return state;
    case FORM_ACTIONS.SET_ZIP:
      const zip = action.payload;
      const zipContainsNonDigit = !!zip.match(NON_DIGIT_REGEX);

      if (zip.length <= MAX_ZIP_LENGTH && !zipContainsNonDigit) {
        const isZipValid = validateZip(zip);

        return {
          ...state,
          zip,
          isZipValid: zip.length === 0 || isZipValid,
        };
      }

      return state;
    case FORM_ACTIONS.RESET_FORM:
      return initialState;
    default:
      return state;
  }
};

const CreditCard = ({ handleChange, handleSubmit, disableSubmit = false, isReadOnly = false }) => {
  const [formState, dispatchFormState] = useReducer(reducer, initialState);

  const [isFormValid, setIsFormValid] = useState(false);
  const [showMaskedCardNumber, setShowMaskedCardNumber] = useState(false);

  useEffect(() => {
    setIsFormValid(
      formState.name && formState.isNameValid &&
      formState.cardNumber && formState.isCardNumberValid &&
      formState.expiration && formState.isExpirationValid &&
      formState.zip && formState.isZipValid
    );

    handleChange(formState);
  }, [ formState ]);

  const handleNameChange = event => {
    const name = event.target.value;

    dispatchFormState({ type: FORM_ACTIONS.SET_NAME, payload: name });
  };

  const handleCardNumberChange = event => {
    const cardNumber = event.target.value;

    dispatchFormState({ type: FORM_ACTIONS.SET_CARD_NUMBER, payload: cardNumber });
  };

  const handleCardNumberFieldOnBlur = () => {
    if (formState.isCardNumberValid) {
      setShowMaskedCardNumber(true);
    }
  };

  const handleCardNumberFieldOnFocus = () => {
    if (showMaskedCardNumber) {
      dispatchFormState({ type: FORM_ACTIONS.SET_CARD_NUMBER, payload: '' });
      setShowMaskedCardNumber(false);
    }
  };

  const handleExpirationChange = event => {
    const expiration = event.target.value;

    dispatchFormState({ type: FORM_ACTIONS.SET_EXPIRATION, payload: expiration});
  };

  const handleZipChange = event => {
    const zip = event.target.value;
    
    dispatchFormState({ type: FORM_ACTIONS.SET_ZIP, payload: zip });
  };

  const handleFormSubmit = () => {
    handleSubmit(formState)
      .then(() => {
        dispatchFormState({ type: FORM_ACTIONS.RESET_FORM });
      })
      .catch((err) => {
        console.error(err);

        if (err && err.errorMessage && err.errorMessage.toLowerCase() === 'invalid credit card number') {
          dispatchFormState({ type: FORM_ACTIONS.SET_IS_CARD_NUMBER_VALID, payload: false });
        }
      });
  };

  return (
    <Form>
      <StyledTextField
        label="Full Name"
        value={formState.name}
        onChange={handleNameChange}
        disabled={isReadOnly}
        error={!formState.isNameValid}
        helperText={!formState.isNameValid && "Enter a valid full name"}
      />

      <StyledTextField
        label="Card Number"
        value={showMaskedCardNumber ? formState.maskedCardNumber : formState.cardNumber}
        onChange={handleCardNumberChange}
        onBlur={handleCardNumberFieldOnBlur}
        onFocus={handleCardNumberFieldOnFocus}
        disabled={isReadOnly}
        error={!formState.isCardNumberValid}
        helperText={!formState.isCardNumberValid && "Enter a valid card number"}
        InputProps={{
          endAdornment: <CardImage cardType={formState.cardType} />
        }}
      />

      <StyledTextField
        label="Expiration Date"
        placeholder="MM/YYYY"
        value={formState.expiration}
        onChange={handleExpirationChange}
        disabled={isReadOnly}
        error={!formState.isExpirationValid}
        helperText={!formState.isExpirationValid && "Enter a valid expiration date"}
      />

      <StyledTextField
        label="Zip Code"
        value={formState.zip}
        onChange={handleZipChange}
        disabled={isReadOnly}
        error={!formState.isZipValid}
        helperText={!formState.isZipValid && "Enter a valid zip"}
      />

      <StyledButton
        onClick={handleFormSubmit}
        disabled={!isFormValid || disableSubmit || isReadOnly}
        variant="contained"
        color="primary"
      >
        Submit
      </StyledButton>
    </Form>
  );
};

export default CreditCard;
