const SEARCH_PARAM_KEY_REGEX = /[^?&=]+/g;

export const parseQueryParams = input => {
  const matches = input.match(SEARCH_PARAM_KEY_REGEX);

  let searchParamsDictionary = {};
  if (matches) {
    for (let index = 0; index < matches.length; index+=2) {
      const key = matches[index];
      let value = matches[index + 1];

      if (key === 'Token') {
        value = value.replace('Bearer%20', '');
      }

      searchParamsDictionary[key] = value;
    }
  }

  return searchParamsDictionary;
};