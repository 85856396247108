import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { CircularProgress } from '@material-ui/core';

import {
  validateOktaToken,
  retrievePaymentCentralToken,
  postPaymentMethodToGuidwire,
} from '../api';
import { parseQueryParams } from '../utils/queryparamparsing';

import CreditCardForm from '../components/CreditCardForm';
import Toast from '../components/Toast';

const Spinner = styled(CircularProgress)`
  z-index: 999;
  position: absolute;
`;

const FormPage = () => {
  const history = useHistory();

  const UUID = uuidv4();

  const [queryParams, setQueryParams] = useState({});
  const [decryptedData, setDecryptedData] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  const [disableFormSubmit, setDisableFormSubmit] = useState(false);
  const [isFormReadOnly, setIsFormReadOnly] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSeverity, setToastSeverity] = useState('success');

  useEffect(() => {
    const parsedQueryParams = parseQueryParams(window.location.search);
    const { token, iv} = parsedQueryParams;

    if (token && iv) {
      setShowSpinner(true);
      setIsFormReadOnly(true);

      validateOktaToken(token,iv, UUID)
        .then((data) => {
          setDecryptedData(data);
          setShowSpinner(false);
          setIsFormReadOnly(false);
        })
        .catch((e) => {
          console.error('Okten Token Endpoint Error', e);
          redirectToErrorPage();
        });
    } else {
      redirectToErrorPage();
    }

    setQueryParams(parsedQueryParams);
  }, []);

  const redirectToErrorPage = () => {
    history.push('/error');
  };

  const handleSubmit = (formState) => {
    setShowSpinner(true);
    setIsFormReadOnly(true);

    return retrievePaymentCentralToken(
      decryptedData.BearerToken,
      formState.cardNumber.replace(/ /g, ''),
      formState.expiration,
      formState.name.trim(),
      formState.zip
    )
      .then((resp) => {
        const { AccountNumber, PaymentMethod, Application, Environment, BearerToken } = decryptedData;

        const GWPayload = {
          AccountNumber: AccountNumber,
          CreditCard: {
            CardType: resp.paymentCardType,
            ExpirationDate: resp.paymentCardExpirationDate.substring(0, 10),
            Last4: resp.paymentCardLast4,
            ZipCode: formState.zip,
          },
          HolderName: formState.name.trim(),
          PaymentMethod: PaymentMethod || 'creditcard',
          Token: resp.paymentAccountToken,
        };

        return postPaymentMethodToGuidwire(BearerToken, GWPayload, Application, Environment)
          .then((resp) => {
            showSuccessToast();

            setShowSpinner(false);
            setIsFormReadOnly(false);

            return resp;
          })
          .catch((err) => {
            // TODO: Get error code to show in error toast
            setShowSpinner(false);
            setIsFormReadOnly(false);

            showErrorToast(
              `Payment account could not be stored due to invalid details. Error Code: #### Correlation ID: ${UUID}`
            );

            throw err;
          });
      })
      .catch((err) => {
        showErrorToast(
          `${err.errorMessage && err.errorMessage.toLowerCase() === 'invalid credit card number' ? 'Invalid Credit Card Number. ' : 'An unknown error occured. '}
          Payment account could not be stored due to invalid details.${err.errorCode ? ` Error Code: ${err.errorCode}` : ''} Correlation ID: ${UUID}`
        );

        setDisableFormSubmit(true);
        setIsFormReadOnly(false);
        setShowSpinner(false);

        throw err;
      });
  };

  const showErrorToast = (message) => {
    setToastMessage(
      message || 'Payment account could not be stored due to invalid details.'
    );
    setToastSeverity('error');
    setShowToast(true);
  };

  const showSuccessToast = (message) => {
    setToastMessage(message || 'Payment account successfully stored.');
    setToastSeverity('success');
    setShowToast(true);
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowToast(false);
  };

  const handleFormChange = () => {
    setDisableFormSubmit(false);
  };

  return (
    <>
      {showSpinner && <Spinner />}

      <CreditCardForm
        handleChange={handleFormChange}
        handleSubmit={handleSubmit}
        disableSubmit={disableFormSubmit}
        isReadOnly={isFormReadOnly}
      />

      <Toast
        open={showToast}
        handleClose={handleToastClose}
        severity={toastSeverity}
        content={toastMessage}
      />
    </>
  );
};

export default FormPage;
