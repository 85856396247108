import wretch from 'wretch';
import moment from 'moment';

import { PaymentCentralClient } from '@aaa-ncnu-ie/payment-central-web-sdk';

let paymentCentralClient;

export const validateOktaToken = (token, iv, uuid) => {
  const url = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_OKTA_TOKEN_VERIFICATION_URL}?iv=${iv}&token=${token}`;

  return wretch(url)
    .headers({ 'X-Correlation-Id': uuid })
    .get()
    .json((json) => {
      return json;
    });
};

export const retrievePaymentCentralToken = (
  oktaToken,
  cardNumber,
  expiration,
  cardHolderName,
  zipCode
) => {
  const client = getPaymentCentralClient(oktaToken);

  const formattedExpiration = moment(expiration, 'MM/YYYY').format(
    'YYYY-MM-DD'
  );

  return client
    .getCreditCardToken({
      paymentSourceSystem: 'MOBIAPP',
      paymentAcctFopType: 'CRDC',
      paymentCardHolderName:cardHolderName,
      paymentCardNumber: Number(cardNumber),
      paymentCardExpirationDate: formattedExpiration,
      paymentCardZip:zipCode
    })
    .then((resp) => {
      if (!resp.success) {
        throw resp;
      }

      return resp.item;
    });
};

export const postPaymentMethodToGuidwire = (
  oktaToken,
  payload,
  application,
  environment
) => {
  const gwUrl = process.env.REACT_APP_GUIDEWIRE_URL
    .replace('application', application)
    .replace('env', environment)
    .toLowerCase();

  return wretch(gwUrl)
    .auth(`Bearer ${oktaToken}`)
    .post(payload)
    .res((resp) => {
      return resp;
    })
    .catch((err) => {
      throw err;
    });
};

const getPaymentCentralClient = (token) => {
  if (paymentCentralClient != null) {
    return paymentCentralClient;
  }

  const client = new PaymentCentralClient(
    process.env.REACT_APP_PAYMENT_CENTRAL_URL,
    token
  );

  return client;
};
