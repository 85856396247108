import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CARD_TYPES } from '../utils/creditcard';

const Container = styled.div`
  padding: 5px;
  max-height: 22px;
  max-width: 28px;
`;

const Img = styled.img`
  max-height: 22px;
  max-width: 28px;
`;

const Div = styled.div`
  height: 22px;
  width: 28px;
`;

const CardImage = ({ cardType }) => {
  const [cardImage, setCardImage] = useState('');

  useEffect(() => {
    setCardImage( cardType ? CARD_TYPES[cardType].img : '');
  }, [ cardType ]);

  return (
    <Container>
      {cardImage ? <Img src={cardImage} alt="" /> : <Div />}
    </Container>
  );
};

export default CardImage;
