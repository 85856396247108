import React from 'react';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const Toast = ({
  open = false,
  handleClose = () => {},
  content = '',
  severity = 'success',
  autoHide = null,
  vertical = 'bottom',
  horizontal = 'center',
}) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical,
        horizontal
      }}
      autoHideDuration={autoHide}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity || 'success'}>
        {content || <></>}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
