import moment from 'moment';
import { visa, amex, discover, mastercard } from '../icons';

const DEFAULT_MAX_LENGTH = 16;
const WHITESPACE_REGEX = /\s+/g;
const NON_DIGIT_REGEX = /[^\d]/g;
const DATE_REGEX = /(?:0?[1-9]|1[0-2])\/[0-9]{4}/g; // Matches MM/YYYY

export const CARD_TYPES = {
  VISA: {
    name: 'VISA',
    lengths: [13, 14, 15, 16],
    get maxLength() {
      return Math.max(...this.lengths);
    },
    matches: value => startsWithOneOf(value, ['4']),
    img: visa,
  },
  DISCOVER: {
    name: 'DISCOVER',
    lengths: [16],
    get maxLength() {
      return Math.max(...this.lengths);
    },
    matches: value => startsWithOneOf(value, ['6011', '644', '649', '65']),
    img: discover,
  },
  MASTER: {
    name: 'MASTER',
    lengths: [16],
    get maxLength() {
      return Math.max(...this.lengths);
    },
    matches: value =>{
      let valueWitouSpaces = value.replace(/ /g, '');
      if (
        startsWithOneOf(value, [
          '51',
          '52',
          '53',
          '54',
          '55',
          '2221',
          '2229',
          '223',
          '229',
          '23',
          '26',
          '270',
          '271',
          '2720'
        ])
      ) {
        return true;
      } else if (
        startsWithOneOf(value, ['2']) &&
        valueWitouSpaces.length >= 6
      ) {
        return withinRange(valueWitouSpaces.substring(0, 6), {
          to: 272099,
          from: 222100
        });
      }
    },
    img: mastercard
  },
  AMEX: {
    name: 'AMEX',
    lengths: [15],
    get maxLength() {
      return Math.max(...this.lengths);
    },
    matches: value => startsWithOneOf(value, ['34', '37']),
    img: amex,
  },
  MASKED: {
    name: 'MASKED',
    lengths: [],
    matches: value => startsWithOneOf(value, ['....']),
  },
};

export const validateCardNumber = value => {
  const formattedValue = value.replace(WHITESPACE_REGEX, '');
  const cardType = getCardType(formattedValue);

  const containsOnlyDigits = !formattedValue.match(NON_DIGIT_REGEX);
  if (!containsOnlyDigits) return false;

  switch (cardType) {
    case CARD_TYPES.AMEX.name:
      return CARD_TYPES.AMEX.lengths.includes(formattedValue.length);
    case CARD_TYPES.DISCOVER.name:
      return CARD_TYPES.DISCOVER.lengths.includes(formattedValue.length);
    case CARD_TYPES.VISA.name:
      return CARD_TYPES.VISA.lengths.includes(formattedValue.length);
    case CARD_TYPES.MASTER.name:
      return CARD_TYPES.MASTER.lengths.includes(formattedValue.length);
    case CARD_TYPES.MASKED.name:
      return true;
    default:
      return false;
  }
};

export const validateExpiryDate = date => {
  const matchesRegex = date.match(DATE_REGEX);
  const isInFuture = moment(date, 'MM/YYYY').diff(moment().startOf('month')) >= 0;
  const isWithinNext15Years = moment(date, 'MM/YYYY').isBefore(moment().add('year', 15));

  return !!matchesRegex && isInFuture && isWithinNext15Years;
};

export const getCardType = value => {
  if (value === '') {
    return '';
  }

  if (CARD_TYPES.MASTER.matches(value)) {
    return CARD_TYPES.MASTER.name;
  } else if (CARD_TYPES.VISA.matches(value)) {
    return CARD_TYPES.VISA.name;
  } else if (CARD_TYPES.AMEX.matches(value)) {
    return CARD_TYPES.AMEX.name;
  } else if (CARD_TYPES.DISCOVER.matches(value)) {
    return CARD_TYPES.DISCOVER.name;
  } else if (CARD_TYPES.MASKED.matches(value)) {
    return CARD_TYPES.MASKED.name;
  }

  return '';
};

export const isCardNumberTooLong = value => {
  const formattedValue = value.replace(WHITESPACE_REGEX, '');
  const cardType = getCardType(formattedValue);

  switch (cardType) {
    case CARD_TYPES.AMEX.name:
      return formattedValue.length > CARD_TYPES.AMEX.maxLength;
    case CARD_TYPES.DISCOVER.name:
      return formattedValue.length > CARD_TYPES.DISCOVER.maxLength
    case CARD_TYPES.VISA.name:
      return formattedValue.length > CARD_TYPES.VISA.maxLength;
    case CARD_TYPES.MASTER.name:
      return formattedValue.length > CARD_TYPES.MASTER.maxLength;
    case CARD_TYPES.MASKED.name:
      return false;
    default:
      return formattedValue.length > DEFAULT_MAX_LENGTH;
  }
};

const startsWithOneOf = (string, startingSequences) => {
  for (let i = 0; i < startingSequences.length; i++) {
    if (string.startsWith(startingSequences[i].substring(0, string.length))) {
      return true;
    }
  }

  return false;
};

const withinRange = (string, range) => {
  let number = parseInt(string);
  return number >= range.from && number <= range.to;
};
