import React from 'react';
import styled from 'styled-components';
import { BrowserRouter, Switch, Route} from 'react-router-dom';

import {
  FormPage,
  ErrorPage
} from './pages';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const App = () => {
  return (
    <Container>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <FormPage />
          </Route>

          <Route path='/error'>
            <ErrorPage />
          </Route>
        </Switch>
      </BrowserRouter>
    </Container>
  );
};

export default App;
